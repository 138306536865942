<template>
  <section>
    <div class="ll-topbox">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Master Data') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'self-pick' }" >
          {{ $t('Self-pick List') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{$route.params.id}}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'self-pick' }"
    >
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    </div>

    <!-- first floor -->
    <b-row>
      <b-col lg="9" class="ll-padding">
        <validation-observer ref="rules">
        <b-card class="ll-card"  :style="style1">
          <b-card-header>
            <b-card-title>{{ $t('Store') }}</b-card-title>
            <b-form-checkbox
              v-model="status"
              class="custom-control-info ll-checkbox"
            >
              {{ $t('Active') }}
            </b-form-checkbox>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Vendor"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Vendor') }}</label>
                    <b-form-input v-model="info.agent"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Location code"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Location code') }}</label>
                    <b-form-input v-model="info.locationid"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Self Pick Type"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Self Pick') }} {{ $t('Type') }}</label>
                    <b-form-input v-model="info.type"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Upload Method"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Upload Method') }}</label>
                    <b-form-input v-model="uploadMethod" :disabled="true"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Store Name CN"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Store Name CN') }}</label>
                    <b-form-input v-model="info.storename"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Store Name EN"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Store Name EN') }}</label>
                    <b-form-input v-model="info.storenameEn"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Store Location CN"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Store Location CN') }}</label>
                    <b-form-input v-model="info.addressCn"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Store Location EN"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Store Location EN') }}</label>
                    <b-form-input v-model="info.addressEn"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Store Area EN"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Store Area EN') }}</label>
                    <b-form-input v-model="info.areaEn"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Area"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Area (CN)') }}</label>
                    <b-form-input v-model="info.areaCn"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Area"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Area (EN)') }}</label>
                    <b-form-input v-model="info.areaEn"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Store District EN"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Store District EN') }}</label>
                    <b-form-input v-model="info.DistrictEn"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="District"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('District (CN)') }}</label>
                    <b-form-input v-model="info.districtCn"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
               <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="District"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('District (EN)') }}</label>
                    <b-form-input v-model="info.districtEn"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Geolocation"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Geolocation') }}</label>
                    <b-form-input v-model="info.position"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Update Date"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Update Date') }}</label>
                    <!-- <b-form-input v-model="info.updatedat"/> -->
                    <flat-pickr
                      v-model="info.updatedat"
                      class="form-control ll-fpickr ll-pickr"
                      disabled
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="false">
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Opening Hours"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Opening Hours') }}</label>
                    <b-form-input v-model="info.openingHours"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Geolocation"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Geolocation') }}</label>
                    <b-form-input v-model="info.position"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ETA EN"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('ETA EN') }}</label>
                    <b-form-input v-model="info.test"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ETA CN"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('ETA CN') }}</label>
                    <b-form-input v-model="info.test"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="false">
              <b-col lg="3">
                <b-form-group
                  :label="$t('Limit EN')"
                  label-for="s"
                >
                  <b-form-input v-model="info.test"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  :label="$t('Limit CN')"
                  label-for="s"
                >
                  <b-form-input v-model="info.test"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  :label="$t('Service Provider EN')"
                  label-for="s"
                >
                  <b-form-input v-model="info.test"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  :label="$t('Service Provider CN')"
                  label-for="s"
                >
                  <b-form-input v-model="info.test"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="false">
              <b-col lg="3">
                <b-form-group
                  :label="$t('Web Opening EN')"
                  label-for="s"
                >
                  <b-form-input v-model="info.test"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  :label="$t('Web Opening CN')"
                  label-for="s"
                >
                  <b-form-input v-model="info.test"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Remarks"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Remarks') }}</label>
                    <b-form-input v-model="info.remark"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  :label="$t('Update Time')"
                  label-for="s"
                >
                  <b-form-input v-model="info.updatedat"/>
                </b-form-group>
              </b-col>
            </b-row>
              <b-card-title>{{ $t('Opening hours') }}</b-card-title>
              <b-row >
                <b-col style="margin-left:50px">
                   <p class=""
                    v-for="(item,index) in openingHours"
                    :key="index" style="line-height:20px;">
                    <span v-if="item.weekday=='Mon'">
                      <b-row style="font-size:14px;margin-bottom:10px;">
                        <b-col lg="2"> </b-col>
                        <b-col lg="3">From</b-col>
                        <b-col lg="3">To</b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="2" >Monday</b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.opening" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.closing" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <b-form-checkbox
                            :checked="(item.closing=='Closed' && item.opening =='Closed') ?true:false"
                            name="is-menu-visible"
                            class="mr-0"
                            @change="checkBoxChange(item.weekday)"
                            inline
                          >Close</b-form-checkbox>
                        </b-col>
                      </b-row>
                    </span>
                    <span v-if="item.weekday=='Tue'">
                      <b-row>
                        <b-col lg="2">Tuesday</b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.opening" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.closing" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <b-form-checkbox
                            :checked="(item.closing=='Closed' && item.opening =='Closed') ?true:false"
                            name="is-menu-visible"
                            @change="checkBoxChange(item.weekday)"
                            class="mr-0"
                            inline
                          >Close</b-form-checkbox>
                        </b-col>
                      </b-row>
                    </span>
                    <span v-if="item.weekday=='Wed'">
                      <b-row>
                        <b-col lg="2">Wednesday</b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.opening" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.closing" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <b-form-checkbox
                            :checked="(item.closing=='Closed' && item.opening =='Closed') ?true:false"
                            name="is-menu-visible"
                            @change="checkBoxChange(item.weekday)"
                            class="mr-0"
                            inline
                          >Close</b-form-checkbox>
                        </b-col>
                      </b-row>
                    </span>
                    <span v-if="item.weekday=='Thu'">
                      <b-row>
                        <b-col lg="2">Thursday</b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.opening" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.closing" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <b-form-checkbox
                            :checked="(item.closing=='Closed' && item.opening =='Closed') ?true:false"
                            name="is-menu-visible"
                            @change="checkBoxChange(item.weekday)"
                            class="mr-0"
                            inline
                          >Close</b-form-checkbox>
                        </b-col>
                      </b-row>
                    </span>
                    <span v-if="item.weekday=='Fri'">
                      <b-row>
                        <b-col lg="2">Friday</b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.opening" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.closing" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <b-form-checkbox
                            :checked="(item.closing=='Closed' && item.opening =='Closed') ?true:false"
                            name="is-menu-visible"
                            @change="checkBoxChange(item.weekday)"
                            class="mr-0"
                            inline
                          >Close</b-form-checkbox>
                        </b-col>
                      </b-row>
                    </span>
                    <span v-if="item.weekday=='Sat'">
                      <b-row>
                        <b-col lg="2">Saturday</b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.opening" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.closing" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <b-form-checkbox
                            :checked="(item.closing=='Closed' && item.opening =='Closed') ?true:false"
                            name="is-menu-visible"
                            @change="checkBoxChange(item.weekday)"
                            class="mr-0"
                            inline
                          >Close</b-form-checkbox>
                        </b-col>
                      </b-row>
                    </span>
                    <span v-if="item.weekday=='Sun'">
                      <b-row>
                        <b-col lg="2">Sunday</b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.opening" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.closing" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <b-form-checkbox
                            :checked="(item.closing=='Closed' && item.opening =='Closed') ?true:false"
                            name="is-menu-visible"
                            @change="checkBoxChange(item.weekday)"
                            class="mr-0"
                            inline
                          >Close</b-form-checkbox>
                        </b-col>
                      </b-row>
                    </span>
                    <span v-if="item.weekday=='Holiday'">
                      <b-row>
                        <b-col lg="2">Holiday</b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.opening" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <el-input placeholder="" v-model="item.closing" size="mini">
                            <template slot="prepend"><i class="el-icon-time"></i></template>
                          </el-input>
                        </b-col>
                        <b-col lg="3">
                          <b-form-checkbox
                            :checked="(item.closing=='Closed' && item.opening =='Closed') ?true:false"
                            name="is-menu-visible"
                            @change="checkBoxChange(item.weekday)"
                            class="mr-0"
                            inline
                          >Close</b-form-checkbox>
                        </b-col>
                      </b-row>
                    </span>
                    <!-- <span>{{item.opening=='Closed' ? (item.opening) : (item.opening+'-'+item.closing)}}</span> -->
                  </p>
                </b-col>
              </b-row>
             
            <div class="ll-subBtn" v-if="isShow">
              <b-button
                variant="primary"
                @click.prevent="validationForm"
              >
                {{ $t('Update') }}
              </b-button>
            </div>
          </b-card-body>
        </b-card>
        </validation-observer>
      </b-col>

      <b-col lg="3">
        <b-card class="ll-card1" :style="style2">
          <!-- <b-card-header>
            <b-card-title></b-card-title>
          </b-card-header> -->
          <b-card-body>
            <div class="ll-map" ref="map"></div>
            
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BFormTextarea,
  BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
  BButton, BSidebar, BInputGroupPrepend, BInputGroup,
  BCardHeader, BCardTitle, BCardBody, BTab, BTabs,
  BPagination, BFormSelect, BFormCheckbox, BFormRadio, BImg,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { debounce } from "@/libs/fun.js"
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BButton,
    BTable,
    flatPickr,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BTab,
    BTabs,
    BPagination,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  data() {
    return {
      required,
      email,
      windowHeight: document.documentElement.clientHeight,
      cardHei: document.documentElement.clientHeight*1,
      cardHei1: document.documentElement.clientHeight*1,
      info: {
        agent: null,
        locationid: null,
        updatedat: null,
        storename: null,
        storenameEn: null,
        area: null,
        district: null,
        status: null,
        addressCn: null,
      },
      status: false,
      longitude: 114.132294,
      latitude: 22.360371,
      isShow: true,
      uploadMethod: 'upload',
      openingHours: [
        {closing:"","opening":"","weekday":"Mon"},
        {closing:"","opening":"","weekday":"Tue"},
        {closing:"","opening":"","weekday":"Wed"},
        {closing:"","opening":"","weekday":"Thu"},
        {closing:"","opening":"","weekday":"Fri"},
        {closing:"","opening":"","weekday":"Sat"},
        {closing:"","opening":"","weekday":"Sun"},
        {closing:"","opening":"","weekday":"Holiday"},]
      }
  },
  computed: {
    style1() {
      return {
        '--height': this.cardHei + 'px'
      }
    },
    style2() {
      return {
        '--height1': this.cardHei1 + 'px'
      }
    }
  },
  watch: {
    windowHeight(val) {
      // console.log(val)
      this.cardHei = val *0.68
      this.cardHei1 = val *0.68
    },
  },
  created() {
   
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        this.windowHeight = window.fullHeight
      })()
    },
    this.getDetails()
    this.initMaps()
  },
  methods: {
    checkBoxChange(val){
      this.openingHours.find(item=>{
        if(item.weekday == val){
          if (item.closing=='Closed' && item.opening =='Closed') {
            item.closing = ''
            item.opening=''
          }else{
            item.closing = 'Closed'
            item.opening='Closed'
          }
        }
      })
    },
    selTime: debounce(function() {
      const dd = this.time.split('to ')
      if (dd[0] !== '') {
        this.info.from = (dd[1] !== undefined)? (dd[0] + '00:00:00') : (dd[0] + ' 00:00:00')
        this.info.to = ((dd[1] !== undefined)? dd[1] : dd[0])  + ' 23:59:59'
      } else {
        this.info.from = null
        this.info.to = null
      }
    },500),
    getDetails() {
      const id = this.$route.params.id
      this.$http.get(`selfpickLocation/getInfoById/${id}`).then( res => {
        const info = res.data.data
        if (info.agentSource == 'KES') {
          this.uploadMethod = 'api'
          this.isShow = false
        } else {
          this.uploadMethod = 'upload'
          this.isShow = true
        }
        this.info = info
        this.status = (info.status !== 'INACTIVE') ? true : false
        if (info.position !== ',' || info.position !== '' || info.position !== null){
          const aa = info.position.split(',')
          this.longitude = Number(aa[0])
          this.latitude = Number(aa[1])
          this.initMaps()
        }
        if (JSON.parse(info.openingHours).length != 0) {
         this.openingHours = JSON.parse(info.openingHours) 
        }
        
      })
    },
    async initMaps() {
      this.maps = await new google.maps.Map(this.$refs.map, {
        zoom: 8,
        center: { lat: this.latitude, lng: this.longitude },
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        language: 'zh_cn',
      })
      this.marker = new google.maps.Marker({
        position: { lat: this.latitude, lng: this.longitude },
        map: this.maps,
      })
    },
    validationForm() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          //  校验成功
          this.update()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    update() {
      this.info.status = this.status? 'ACTIVE': 'INACTIVE'
      this.info.openingHours = JSON.stringify(this.openingHours)
      this.$http.put('/selfpickLocation/edit',this.info).then(res => {
        if (res.data.code == '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'self-pick' })
        }
      })
    },
  },
}
</script>

<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 10px;
}
.ll-select {
  display: inline-block;
  width: 90%;
}
.ll-card{
  margin-bottom: 10px;
  height: var(--height);
}
.ll-card1{
  height: var(--height1);
}
.ll-card1 .card-body{
  padding: 1rem;
}
.ll-padding{
  padding-right: 0;
}
.ll-map{
  width: 100%;
  height: 230px;
  margin-bottom: 10px;
}
.ll-pickr [dir] .form-control:disabled, [dir] .form-control[readonly]{
  background-color:#efefef !important
}
</style>